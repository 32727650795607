import { showMessage } from "../helpers/global_helper";

class AudioManager {
    constructor() {
        if (!AudioManager.instance) {
            this.audioInstances = new Map(); // Store multiple audio instances
            this.abortControllers = new Map(); // Store abort controllers for each audio
            AudioManager.instance = this;
        }
        return AudioManager.instance;
    }

    addAudio(id, audioFile, loop = true, volume = 1.0) {
        if (this.audioInstances.has(id)) {
            return;
        }
        const audio = new Audio(audioFile);
        audio.loop = loop;
        audio.volume = volume
        audio.muted = true; // Start muted to comply with autoplay policies
        this.audioInstances.set(id, audio);
        this.abortControllers.set(id, new AbortController());
    }

    play(id, volume = null) {
        const audio = this.audioInstances.get(id);
        const controller = this.abortControllers.get(id);

        if (audio && controller) {
            if (volume !== null) {
                audio.volume = volume; // Adjust volume if specified
            }
            controller.signal.addEventListener("abort", () => {
                audio.pause(); // Stop playback immediately
            });

            audio.muted = false; // Unmute before playing
            audio.play().catch((error) => {
                if (controller.signal.aborted) {
                    console.log(`Audio play aborted for ID "${id}".`);
                } else {
                    console.log(`Error playing audio with ID "${id}": ${error}`);
                }
            });
        } else {
            console.log(`No audio found with ID "${id}".`);
        }
    }

    abortPlay(id) {
        const controller = this.abortControllers.get(id);
        if (controller) {
            controller.abort(); // Signal the play() promise to stop
            this.abortControllers.set(id, new AbortController()); // Reset the controller for reuse
        }
    }

    pause(id) {
        const audio = this.audioInstances.get(id);
        if (audio) {
            audio.pause();
        } else {
            console.log(`No audio found with ID "${id}".`);
            return;
        }
    }

    setVolume(id, volume) {
        const audio = this.audioInstances.get(id);
        if (audio) {
            audio.volume = volume; // Volume range: 0.0 to 1.0
        } else {
            console.log(`No audio found with ID "${id}".`);
            return;
        }
    }

    removeAudio(id) {
        const audio = this.audioInstances.get(id);
        const controller = this.abortControllers.get(id);

        if (audio) {
            audio.pause(); // Stop playback
            this.audioInstances.delete(id); // Remove from the collection
        }

        if (controller) {
            controller.abort(); // Abort any pending play
            this.abortControllers.delete(id);
        }
    }

    unmute(id) {
        const audio = this.audioInstances.get(id);
        if (audio) {
            audio.muted = false; // Stop playback
        } else {
            console.log(`No audio found with ID "${id}".`);
            return;
        }
    }

    initPlayOnInteraction(id, volume = null) {
        const audio = this.audioInstances.get(id);
        if (!audio) {
            console.log(`No audio found with ID "${id}".`);
            return;
        }
        const enableAudio = () => {
            this.play(id, volume);
            window.removeEventListener('click', enableAudio); // Remove the click event listener after execution
        };
        // Attach the interaction event
        window.addEventListener('click', enableAudio, { once: true });
    }

    initPlayOnLoad(id, volume = null) {
        const audio = this.audioInstances.get(id);
        if (!audio) {
            console.log(`No audio found with ID "${id}".`);
            return;
        }
        this.play(id, volume);
    }
}

const audioManager = new AudioManager();
export default audioManager;
import React from 'react';
import 'react-app-polyfill/ie11'; // For IE11 or older browsers
import 'react-app-polyfill/stable'; // For stable polyfill
import 'core-js';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from "./redux/store";
import "./i18n";
import { FpjsProvider, FingerprintJSPro } from '@fingerprintjs/fingerprintjs-pro-react';

const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
  <>
    <Provider store={store}>
      <FpjsProvider
        loadOptions={{
          apiKey: "ZQ2KE5fWMCHxekaE4D8a",
          endpoint: [
            "https://log.api-admcanvas.com",
            FingerprintJSPro.defaultEndpoint
          ],
          scriptUrlPattern: [
            "https://log.api-admcanvas.com/web/v<version>/<apiKey>/loader_v<loaderVersion>.js",
            FingerprintJSPro.defaultScriptUrlPattern
          ],
          region: "ap"
        }}
      >
        <App />
      </FpjsProvider>
    </Provider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
